import React, { useContext, useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  LanguageContext,
  Logo,
  Icon,
  PrinterIcon,
  colors,
} from "@eriksdigital/atomic-ui";

import Hamburger from "../Hamburger";
import { StyledSelect } from "../Select/style";
import {
  HeaderWrapper,
  HeaderInnerWrapper,
  HeaderInner,
  LogoWrapper,
  MenuPanelContainer,
  LanguagePanel,
} from "./style";
import { constructUrl } from "../../utils/shopUtils";
import { pimLocalesDropdown } from "../../utils/languageUtils";
import { GET_AVAILABLE_LANGUAGES } from "./queries";

const environment = process.env.REACT_APP_ENVIRONMENT;

const Header = () => {
  const { language, setLanguage, erpSystem } = useContext(LanguageContext);
  const [isMenuOpen, toggleMenu] = useState(false);
  const menu = useRef(null);
  const [languageDropdown, updateLanguageDropdown] = useState(
    Object.values(pimLocalesDropdown)
  );
  const { data } = useQuery(GET_AVAILABLE_LANGUAGES, {
    variables: {
      erpSystem: erpSystem.toUpperCase(),
    },
  });
  const availableLanguages = data && data.getAvailableLanguages;

  useEffect(() => {
    if (availableLanguages) {
      const newDropdown = availableLanguages.map(
        (lang) => pimLocalesDropdown[lang.countryCode]
      );
      updateLanguageDropdown(newDropdown);
      if (!newDropdown.find((locale) => locale.value === language)) {
        setLanguage(newDropdown[0].value);
      }
    }
  }, [language, availableLanguages, setLanguage]);

  const handleChangeLanguage = ({ target: { value } }) => {
    setLanguage(value);
  };

  const handleMenuToggle = () => {
    toggleMenu(!isMenuOpen);
  };

  const handleClickOutside = (e) => {
    if (menu.current.contains(e.target)) {
      return;
    }

    toggleMenu(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => document.removeEventListener("click", handleClickOutside);
  }, [isMenuOpen]);

  return (
    <HeaderWrapper data-testid="header">
      <HeaderInnerWrapper>
        <HeaderInner>
          <LogoWrapper href={constructUrl(environment, erpSystem, language)}>
            <Logo />
          </LogoWrapper>
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <Icon
              style={{ marginRight: "1rem" }}
              as={PrinterIcon}
              onClick={window.print}
              size="xxxl"
              color={colors.default.blueD}
            />
            <Hamburger
              id="menu-button"
              onClick={handleMenuToggle}
              open={isMenuOpen}
            />
          </div>
        </HeaderInner>
        <MenuPanelContainer id="menu" ref={menu}>
          <LanguagePanel data-testid="menu-panel" menuOpen={isMenuOpen}>
            <StyledSelect
              data-testid="language-select"
              onChange={handleChangeLanguage}
              value={language}
            >
              {languageDropdown.map((lang) => {
                return (
                  <option value={lang.value} key={lang.value}>
                    {lang.label}
                  </option>
                );
              })}
            </StyledSelect>
          </LanguagePanel>
        </MenuPanelContainer>
      </HeaderInnerWrapper>
    </HeaderWrapper>
  );
};

export default Header;
