import { isGreaterThanOrEqualToFloor } from "./validations";
import get from "lodash/get";

export const getTractionLoadRatio = ratio => {
  if (!ratio) return false;
  if (isGreaterThanOrEqualToFloor(ratio, 90))
    return "errors.stresses.tractionLoadRatio";
  return false;
};

export const getFinalLoadRatio = ratio => {
  if (!ratio) return false;
  if (isGreaterThanOrEqualToFloor(ratio, 99))
    return "errors.stresses.finalLoadRatio";
  return false;
};

export const getStressErrors = stresses => {
  return {
    // add errors for other fields as required
    tractionLoadRatio: getTractionLoadRatio(
      get(stresses, "getGasketCalculation.stresses.tractionLoadRatio", "")
    ),
    finalLoadRatio: getFinalLoadRatio(
      get(stresses, "getGasketCalculation.stresses.finalLoadRatio", "")
    )
  };
};
