const languages = {
  nl: 31,
  en: -1,
  fr: -2,
  de: -3,
};

export const languageToCode = (language) => {
  return languages[language] || 31;
};

export const fetchTranslations = async ({
  appId = "gasket-calculator",
  language = "nl",
  filePath = "../locales/",
}) => {
  try {
    const module = await import(`../locales/${appId}-${language}`);
    return module.default;
  } catch (error) {
    try {
      const fallback = await import("../locales/gasket-calculator-nl");
      return fallback.default;
    } catch (error) {
      console.log("Yikes.");
    }
  }
};

export const pimLocalesDropdown = {
  "31": {
    value: "nl",
    label: "Nederlands",
  },
  "-1": {
    value: "en",
    label: "English",
  },
  "-2": {
    value: "fr",
    label: "Français",
  },
  "-3": {
    value: "de",
    label: "Deutsch",
  },
};
