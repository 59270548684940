import React, { Fragment, memo } from "react";
import {
  H5,
  Icon,
  TooltipIcon,
  TooltipHost,
  colors,
} from "@eriksdigital/atomic-ui";
import get from "lodash/get";

import { T } from "../Translation";

import {
  ResultsBox,
  IconCircle,
  DisplayResults,
  DisplayResultsHeader,
  DisplayResultsValue,
  DisplayResultsDescription,
  DisplayResultsContainer,
  Unit,
  P,
} from "./styles";

export const CalculationResultDisplay = memo(({ data, template, grow }) => {
  const { title, info, icon, values } = template;

  return (
    <ResultsBox grow={grow}>
      <DisplayResultsHeader>
        <IconCircle>
          <Icon as={icon} size="lg" color={colors.default.white} />
        </IconCircle>

        <H5>{title}</H5>

        {info && (
          <Icon as={TooltipIcon} id={`torque-load`} color="#000" size="sz16" />
        )}
        {info && <TooltipHost parent={`torque-load`}>{info}</TooltipHost>}
      </DisplayResultsHeader>

      <DisplayResultsContainer>
        {values.map((value) => {
          const key = value.label.replace(/\s/g, "");
          const notValid = get(value, "error", false);
          return (
            <DisplayResults key={key}>
              <DisplayResultsValue data-testid={key} notValid={notValid}>
                <P bold={value.bold} notValid={notValid}>
                  {get(data, value.path, false) ? (
                    <Fragment>
                      {get(data, value.path, "")}
                      <Unit bold={value.bold} inherit={value.inherit}>
                        {get(value, "unit", "")}
                      </Unit>
                    </Fragment>
                  ) : (
                    `[${get(value, "unit", "")}]`
                  )}
                </P>
              </DisplayResultsValue>
              <DisplayResultsDescription notValid={notValid}>
                <T>{value.label}</T>
              </DisplayResultsDescription>
            </DisplayResults>
          );
        })}
      </DisplayResultsContainer>
    </ResultsBox>
  );
});
