import styled from "styled-components";

export const HamBar = styled.span`
  background-color: ${props => props.color};
  display: block;
  height: 0.125rem;
  margin: 0.25rem auto;
  transition: all 0.3s ease-in-out;
  width: 1.5rem;

  &:nth-child(2) {
    opacity: ${props => (props.open ? 0 : 1)};
  }

  &:nth-child(1) {
    transform: ${props =>
      props.open ? "translate(0px, 7px) rotate(45deg)" : "none"};
  }

  &:nth-child(3) {
    transform: ${props =>
      props.open ? "translate(0px, -5px) rotate(-45deg)" : "none"};
  }
`;

export const MenuButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;

  // uncommon spacing 40x40px
  padding: 0.5625rem 0.5rem;
`;
