import React from "react";
import PropTypes from "prop-types";

import { MenuButton, HamBar } from "./style";

const Hamburger = props => {
  const { onClick, ...rest } = props;

  return (
    <MenuButton data-testid="menu-button" onClick={onClick}>
      <HamBar {...rest} />
      <HamBar {...rest} />
      <HamBar {...rest} />
    </MenuButton>
  );
};

Hamburger.defaultProps = {
  onClick: null,
  color: "#0163af",
  open: false
};

Hamburger.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  open: PropTypes.bool
};

Hamburger.displayName = "Hamburger";

export default Hamburger;
