/*
 * List of validations for Gasket Calculator input fields and dropdowns
 */
export const isRequired = value => !!(value && value.length);

export const isInteger = value => Number.isInteger(Number(value));

export const isFloat = value => {
  const parsedValued = parseFloat(value);

  return Number(parsedValued) === parsedValued && parsedValued % 1 !== 0;
};

export const isLengthValid = value => value.length <= 5;

export const isGreaterThanZero = value =>
  value.length === 0 ||
  ((isFloat(value) || isInteger(value)) && Number(value) > 0);

export const isZeroOrGreater = value =>
  (isFloat(value) || isInteger(value)) && Number(value) >= 0;

export const isLowerThanLimit = (value, limit) =>
  (isFloat(value) || isInteger(value)) && Number(value) < Number(limit);

export const isGreaterThanFloor = (value, floor) =>
  (isFloat(value) || isInteger(value)) && Number(value) > Number(floor);

export const isLowerThanOrEqualToLimit = (value, limit) =>
  (isFloat(value) || isInteger(value)) && Number(value) <= Number(limit);

export const isGreaterThanOrEqualToFloor = (value, floor) =>
  value.length === 0 ||
  ((isFloat(value) || isInteger(value)) && Number(value) >= Number(floor));

export const mapRuleToHandler = {
  required: isRequired,
  numeric: isInteger,
  maxlength: isLengthValid,
  positive: isGreaterThanZero,
  notNegative: isZeroOrGreater,
  lowerThan: isLowerThanLimit,
  greaterThan: isGreaterThanFloor,
  lowerThanOrEqualTo: isLowerThanOrEqualToLimit,
  greaterThanOrEqualTo: isGreaterThanOrEqualToFloor,
  gasketTemperature: isLowerThanOrEqualToLimit,
  boltTemperature: isLowerThanOrEqualToLimit
};
