import styled from "styled-components";
import {
  colors,
  StressBar as unStyledStressBar
} from "@eriksdigital/atomic-ui";

export const StressBarBox = styled.div`
  background-color: ${colors.default.white};
  box-shadow: 0 3px 8px 0 rgba(112, 120, 135, 0.24);
  height: 180px;
  padding: 16px;
  position: relative;
  z-index: -1;
`;

export const StressIndicatorWrapper = styled.div`
  display: flex;
  flex-basis: 328px;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 32px;

  ${StressBarBox} + ${StressBarBox} {
    margin-top: 24px;
  }

  h5 {
    line-height: 24px;
    margin: 0;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 22px;
  top: 18px;
`;

export const Span = styled.span`
  color: ${colors.default.darkGray};
  font-size: 14px;
`;

export const StressBar = styled(unStyledStressBar)`
  margin-top: 64px;
  margin-left: 22px;

  div:nth-child(3) {
    left: 2px;
  }

  div:nth-child(3) > div {
    left: -11px;
  }
`;
