import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  padding: 3px 0px;
`;

export const StyledSelectLabel = styled.label`
  font-size: 0.75rem;
`;

export const SelectWrapper = styled.div`
  cursor: pointer;
`;

export const StyledSelect = styled.select`
  border-radius: 2px;
  border: 1px solid #d2d0d0;
  min-width: 100%;
  padding-right: 25px;
  padding: 0.5rem;
`;
