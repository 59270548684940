import React, { memo } from "react";
import {
  H4,
  H5,
  colors,
  TooltipIcon,
  RulerIcon,
  Icon,
  TooltipHost,
} from "@eriksdigital/atomic-ui";

import { T } from "../../../components/Translation";

import { InputForm, ActualArea, Header, Value } from "../styles";
import { InputSelector } from "../../../components/InputSelector";
import {
  P,
  Unit,
  IconCircle,
} from "../../../components/CalculationResultDisplay/styles";
import { gasketInputs } from "./StepInputs";
import sleep from "../../../utils/sleep";

const GasketsDetails = memo(
  ({
    dropdownData,
    actualArea,
    isInvalidCalculation,
    updateFormik,
    updateFormikMulti,
    setFieldTouched,
    gasketValues,
    errors,
    touched,
    gasketMaterialsList,
  }) => {
    const updateField = async ({ currentTarget: { id, value } }) => {
      updateFormik(id, value);

      if (!touched[id]) {
        await sleep(0);
        setFieldTouched(id, true);
      }
    };

    const updateReactSelect = async (e, name) => {
      if (name === "gasketMaterial") {
        const chosenGasket = gasketMaterialsList.find(
          (mat) => mat.materialName === e.value
        );

        updateFormikMulti({
          ...gasketValues,
          [name]: e.value,
          gasketMaterialMaxTemperature: chosenGasket.maxTemperature,
        });

        if (!touched[name]) {
          await sleep(0);

          setFieldTouched(name, true);
        }
        return;
      }

      updateFormikMulti({
        ...gasketValues,
        [name]: e.value,
      });

      if (!touched[name]) {
        await sleep(0);

        setFieldTouched(name, true);
      }
    };

    return (
      <InputForm column data-testid={"gasket-input-form"}>
        <H4>
          <T>gasket.title</T>
        </H4>
        <InputSelector
          inputs={gasketInputs(gasketValues, errors, touched)}
          dropdownData={dropdownData}
          errors={errors}
          touched={touched}
          updateReactSelect={updateReactSelect}
          updateField={updateField}
          state={gasketValues}
        />

        <ActualArea>
          <Header>
            <IconCircle>
              <Icon as={RulerIcon} size="lg" color={colors.default.white} />
            </IconCircle>

            <H5>
              <T>gasket.actualArea</T>
            </H5>

            <Icon as={TooltipIcon} id="actual-area" color="#000" size="sz16" />

            <TooltipHost parent="actual-area">
              <T>gasket.actualArea-info</T>
            </TooltipHost>
          </Header>
          <Value>
            {actualArea && !isInvalidCalculation ? (
              <P data-testid="actual-area-value">
                {actualArea}
                <Unit>
                  mm<sup>2</sup>
                </Unit>
              </P>
            ) : (
              "-"
            )}
          </Value>
        </ActualArea>
      </InputForm>
    );
  }
);

export default GasketsDetails;
