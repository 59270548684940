import styled, { css } from "styled-components";
import { colors } from "@eriksdigital/atomic-ui";

export const DisplayResults = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  padding: 24px 0;
`;

export const DisplayResultsValue = styled.div`
  color: ${({ notValid }) =>
    notValid ? colors.default.red : colors.default.blueD};
`;

export const DisplayResultsDescription = styled.div`
  color: ${({ notValid }) =>
    notValid ? colors.default.red : colors.default.blueD};
`;

const BigBox = css`
  flex-grow: 1;
  flex-shrink: 0;
  margin-left: 32px;
`;

const SmallBox = css`
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 328px; /* Same as Assembly column */
`;

export const HeadingWithIcon = css`
  h5 {
    margin: 0 0 0 32px;
  }

  h5 + svg {
    position: absolute;
    right: 16px;
    top: 16px;
  }
`;

export const ResultsBox = styled.div`
  background-color: ${colors.default.white};
  box-shadow: 0 3px 8px 0 rgba(112, 120, 135, 0.24);
  display: flex;
  flex-shrink: 1;
  flex-direction: column;

  ${HeadingWithIcon}

  ${({ grow }) => (grow ? BigBox : SmallBox)};
`;

export const DisplayResultsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${DisplayResults} + ${DisplayResults} {
    border-left: 1px solid ${colors.default.gray};
  }
`;

export const DisplayResultsHeader = styled.div`
  align-items: center;
  display: flex;
  padding: 16px 16px 24px;
  position: relative;
  border-bottom: 1px solid ${colors.default.gray};
`;

export const IconCircle = styled.div`
  align-items: center;
  background-color: ${colors.default.blueD};
  border-radius: 24px;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
`;

const BoldStyles = css`
  font-size: inherit;
  font-weight: 600;
`;

export const Unit = styled.span`
  color: ${({ inherit }) => (inherit ? "inherit" : colors.default.gray)};
  font-size: ${({ inherit }) => (inherit ? "inherit" : "12px")};
  margin-left: 2px;

  ${({ bold }) => (bold ? BoldStyles : null)}
`;

export const P = styled.p`
  color: ${({ notValid }) =>
    notValid ? colors.default.red : colors.default.blueD};
  font-size: 16px;

  ${({ bold }) => (bold ? BoldStyles : null)}
`;
