import React from "react";
import { defaultTheme, LanguageProvider } from "@eriksdigital/atomic-ui";
import { ThemeProvider } from "styled-components";

import { fetchTranslations } from "./utils/languageUtils";

import Header from "./components/Header";
import GasketCalculator from "./containers/GasketCalculator";
import { isNewDomain } from "./utils/isNewDomain";

const App = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <LanguageProvider
        shouldUpdateURL
        fetchTranslations={fetchTranslations}
        hasNewDomain={isNewDomain()}
      >
        <Header />
        <GasketCalculator />
      </LanguageProvider>
    </ThemeProvider>
  );
};

export default App;
