/**
 * ErrorMessage component: displays one or multiple error messages with a
 * stacked layout
 *
 * @param {string} fieldName - the name of the field
 * @param {[string]} errorList - array of errors to show
 * @param {translationParams} someParam - parameters to spread into the Translation component
 *
 *
 * @example
 *
 * <ErrorMessage
 *   fieldName="someField"
 *     errorList={errors["someField"]}
 *     number={68}
 *   />
 */

import React, { Fragment } from "react";

import { ErrorText } from "./styles";

const ErrorMessage = ({ errorList, fieldName, touched, ...rest }) => {
  if (!touched) return <></>;
  if (!errorList || !errorList.length) {
    return <Fragment></Fragment>;
  }

  return (
    <ErrorText>
      {[errorList].map(error => (
        <span
          key={`${fieldName}-error-${error}`}
          data-testid={`${fieldName}-error`}
        >
          {error}
        </span>
      ))}
    </ErrorText>
  );
};

export default ErrorMessage;
