import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { Icon, H5 } from "@eriksdigital/atomic-ui";

import stressColorScheme from "../../utils/stressColorScheme";

import { T } from "../Translation";

import {
  StressIndicatorWrapper,
  StressBarBox,
  IconWrapper,
  Span,
  StressBar
} from "./styles";

export const calculateStressValues = data => {
  if (!data) {
    return null;
  }

  const asmResultBar = get(data, "getGasketCalculation.asmResultBar", {});
  const tstResultBar = get(data, "getGasketCalculation.tstResultBar", {});
  const srvResultBar = get(data, "getGasketCalculation.srvResultBar", {});
  const asmColorScheme = stressColorScheme(asmResultBar);
  const tstColorScheme = stressColorScheme(tstResultBar);
  const srvColorScheme = stressColorScheme(srvResultBar);

  const stressValues = {
    assembly: {
      minValue:
        asmResultBar.initialRange && asmResultBar.initialRange !== 0
          ? asmResultBar.initialRange
          : 0,
      maxValue:
        asmResultBar.finalRange && asmResultBar.finalRange !== 0
          ? asmResultBar.finalRange
          : 200,
      to: asmResultBar.result || 0,
      tooltipColor: asmColorScheme.color,
      markerColor: asmColorScheme.color,
      icon: asmColorScheme.icon
    },
    test: {
      minValue:
        tstResultBar.initialRange && tstResultBar.initialRange !== 0
          ? tstResultBar.initialRange
          : 0,
      maxValue:
        tstResultBar.finalRange && tstResultBar.finalRange !== 0
          ? tstResultBar.finalRange
          : 200,
      to: tstResultBar.result || 0,
      tooltipColor: tstColorScheme.color,
      markerColor: tstColorScheme.color,
      icon: tstColorScheme.icon
    },
    service: {
      minValue:
        srvResultBar.initialRange && srvResultBar.initialRange !== 0
          ? srvResultBar.initialRange
          : 0,
      maxValue:
        srvResultBar.finalRange && srvResultBar.finalRange !== 0
          ? srvResultBar.finalRange
          : 200,
      to: srvResultBar.result || 0,
      tooltipColor: srvColorScheme.color,
      markerColor: srvColorScheme.color,
      icon: srvColorScheme.icon
    }
  };

  return stressValues;
};

const StressIndicators = ({ data, isInvalidCalculation, children }) => {
  const defaultValues = {
    assembly: {
      minValue: 0,
      maxValue: 200,
      to: 0
    },
    test: {
      minValue: 0,
      maxValue: 200,
      to: 0
    },
    service: {
      minValue: 0,
      maxValue: 200,
      to: 0
    }
  };

  const [stressValues, setStressValues] = useState(defaultValues);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      if (isInvalidCalculation) {
        setStressValues(defaultValues);
      } else {
        const sv = calculateStressValues(data);

        sv && setStressValues(sv);
      }
    }
    // eslint-disable-next-line
  }, [data, isInvalidCalculation]);

  return (
    <StressIndicatorWrapper>
      <StressBarBox>
        <H5>
          <T>conditions.title</T>
        </H5>
        <Span>
          <T>initial-stress</T>
        </Span>
        <StressBar
          {...stressValues.assembly}
          data-testid="assembly-stressbar"
          data-minvalue={stressValues.assembly.minValue}
          data-maxvalue={stressValues.assembly.maxValue}
          data-stressvalue={stressValues.assembly.to}
          from={0}
        />
        <IconWrapper>
          <Icon
            size="xxxl"
            as={stressValues.assembly.icon}
            color={stressValues.assembly.tooltipColor}
          />
        </IconWrapper>
      </StressBarBox>

      <StressBarBox>
        <H5>
          <T>test.title</T>
        </H5>
        <Span>
          <T>residual-stress</T>
        </Span>
        <StressBar
          {...stressValues.test}
          data-testid="test-stressbar"
          data-minvalue={stressValues.test.minValue}
          data-maxvalue={stressValues.test.maxValue}
          data-stressvalue={stressValues.test.to}
          from={0}
        />
        <IconWrapper>
          <Icon
            size="xxxl"
            as={stressValues.test.icon}
            color={stressValues.test.tooltipColor}
          />
        </IconWrapper>
      </StressBarBox>

      <StressBarBox>
        <H5>
          <T>service.title</T>
        </H5>
        <Span>
          <T>residual-stress</T>
        </Span>
        <StressBar
          {...stressValues.service}
          data-testid="service-stressbar"
          data-minvalue={stressValues.service.minValue}
          data-maxvalue={stressValues.service.maxValue}
          data-stressvalue={stressValues.service.to}
          from={0}
        />
        <IconWrapper>
          <Icon
            size="xxxl"
            as={stressValues.service.icon}
            color={stressValues.service.tooltipColor}
          />
        </IconWrapper>
      </StressBarBox>
      {children}
    </StressIndicatorWrapper>
  );
};

export default StressIndicators;
