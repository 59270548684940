import pickBy from "lodash/pickBy";
import get from "lodash/get";

const prepareQueryVariables = state => {
  const {
    boltMaterialMaxTemperature,
    boltMaterialMaxDiameter,
    boltDiameterDbo,
    boltDiameterHole,
    gasketMaterialMaxTemperature,
    ...rest
  } = state;
  const filteredState = pickBy(rest, val => val !== "");
  const vdiCalculation = get(rest, "vdiCalculation", "false");
  const srvBoltTemp = get(
    filteredState,
    "srvBoltTemp",
    filteredState.srvTemperature * 0.8
  );

  return {
    ...filteredState,
    srvBoltTemp: Number(srvBoltTemp),
    vdiCalculation: vdiCalculation === "false" ? false : true,
    locale: -1
  };
};

export default prepareQueryVariables;
