import { createGlobalStyle } from "styled-components";
import { colors, fonts, fontFace } from "@eriksdigital/atomic-ui";

const GlobalStyles = createGlobalStyle`
  ${fontFace}

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
      -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
      color-adjust: exact !important;                 /*Firefox*/
  
  }

  body {
    background-color: ${colors.default.lightGray};
    font-family: ${fonts.fontFamily.primary};
    font-weight: ${fonts.fontWeight.normal};
  }

  main {
    margin: 0 auto;
    max-width: 1442px; // 16px padding each side
  }

  body,
  p,
  ul,
  h1,
  h2,
  h3,
  h4,
  fieldset,
  label {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style-type: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  fieldset {
    border: none;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  select {
    appearance: none;
    background-image: none;
    background: transparent;
  }

  select::-ms-expand {
    visibility: hidden;
  }

  label {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 8px;
  }
`;

export default GlobalStyles;
