import gql from "graphql-tag";

export const GET_AVAILABLE_LANGUAGES = gql`
  query getAvailableLanguages($erpSystem: String!) {
    getAvailableLanguages(erpSystem: $erpSystem) {
      countryCode
      countryName
    }
  }
`;
