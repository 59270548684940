import { useContext } from "react";
import { LanguageContext } from "@eriksdigital/atomic-ui";

export const formatMessage = (locales = {}, message, rest = {}) => {
  if (Object.keys(locales).length === 0) return message;
  return Object.keys(rest).length
    ? Object.keys(rest).reduce((str, key) => {
        return str.replace(`{${key}}`, rest[key]);
      }, locales[message])
    : locales[message]
    ? locales[message]
    : message;
};

export const T = ({ children, ...rest }) => {
  const { strings } = useContext(LanguageContext);

  if (Object.keys(strings).length === 0) return children;

  return Object.keys(rest).length
    ? Object.keys(rest).reduce((str, key) => {
        return str.replace(`{${key}}`, rest[key]);
      }, strings[children])
    : strings[children]
    ? strings[children]
    : children;
};
