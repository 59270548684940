import React, { useContext, memo } from "react";
import {
  H4,
  Icon,
  TooltipIcon,
  TooltipHost,
  Span,
  LanguageContext,
  RadioButton,
  RadioButtonGroup,
} from "@eriksdigital/atomic-ui";

import ErrorMessage from "../../../components/ErrorMessage";
import { T, formatMessage } from "../../../components/Translation";

import {
  InputForm,
  InputFormSection,
  TextField,
  InputFormSectionHeader,
} from "../styles";

const ConditionsDetails = memo(
  ({ updateFormik, setFieldTouched, conditionValues, errors, touched }) => {
    const { strings } = useContext(LanguageContext);
    const updateField = (event) => {
      let {
        currentTarget: { id, value },
      } = event;

      setFieldTouched(id);
      updateFormik(id, value);
    };

    const handleVdiCalculationToggle = ({ target: { value } }) => {
      setFieldTouched("vdiCalculation");
      updateFormik("vdiCalculation", value);
    };

    return (
      <InputForm data-testid={"condition-input-form"}>
        <InputFormSectionHeader>
          <H4>
            <T>conditions.title</T>
          </H4>{" "}
          <Icon
            id={`info-assembly`}
            as={TooltipIcon}
            color="#000"
            size="sz16"
          />
        </InputFormSectionHeader>
        <TooltipHost parent={`info-assembly`}>
          <Span>
            <T>conditions.title-info</T>
          </Span>
        </TooltipHost>

        <InputFormSection>
          <TextField
            name="assemblyTemperature"
            id="assemblyTemperature"
            data-testid="assemblyTemperature"
            width={160}
            label={<T>label.temperature</T>}
            placeholder="20"
            disabled
            data-hj-whitelist
          />
          <TextField
            name="assemblyPressure"
            id="assemblyPressure"
            data-testid="assemblyPressure"
            label={<T>label.pressure</T>}
            placeholder="0"
            disabled
            width={100}
            data-hj-whitelist
          />
        </InputFormSection>
        <InputFormSectionHeader>
          <H4>
            <T>test.title</T>
          </H4>{" "}
          <Icon id={`info-test`} as={TooltipIcon} color="#000" size="sz16" />
        </InputFormSectionHeader>
        <TooltipHost parent={`info-test`}>
          <Span>
            <T>test.title-info</T>
          </Span>
        </TooltipHost>

        <InputFormSection>
          <TextField
            name="testTemperature"
            id="testTemperature"
            data-testid="testTemperature"
            width={160}
            label={<T>label.temperature</T>}
            placeholder="20"
            disabled
            data-hj-whitelist
          />
          <TextField
            name="tstPressure"
            id="tstPressure"
            data-testid="tstPressure"
            onBlur={updateField}
            error={
              touched["tstPressure"] &&
              errors["tstPressure"] &&
              errors["tstPressure"].length > 0
            }
            label={<T>label.pressure</T>}
            placeholder="0 Bar"
            errorMessage={
              <ErrorMessage
                fieldName="tstPressure"
                errorList={errors["tstPressure"]}
                touched={touched["tstPressure"]}
              />
            }
            width={100}
            data-hj-whitelist
            data-dependentfields=""
          />
        </InputFormSection>
        <InputFormSectionHeader>
          <H4>
            <T>service.title</T>
          </H4>{" "}
          <Icon id={`info-service`} as={TooltipIcon} color="#000" size="sz16" />
        </InputFormSectionHeader>
        <TooltipHost parent={`info-service`}>
          <Span>
            <T>service.title-info</T>
          </Span>
        </TooltipHost>
        <InputFormSection>
          <TextField
            name="srvTemperature"
            id="srvTemperature"
            data-testid="srvTemperature"
            onBlur={updateField}
            error={
              touched["srvTemperature"] &&
              errors["srvTemperature"] &&
              errors["srvTemperature"].length > 0
            }
            width={160}
            label={<T>label.temperature</T>}
            placeholder="20°C"
            errorMessage={
              <ErrorMessage
                fieldName="srvTemperature"
                errorList={errors["srvTemperature"]}
                touched={touched["srvTemperature"]}
              />
            }
            data-hj-whitelist
          />
          <TextField
            name="srvPressure"
            id="srvPressure"
            data-testid="srvPressure"
            onBlur={updateField}
            error={
              touched["srvPressure"] &&
              errors["srvPressure"] &&
              errors["srvPressure"].length > 0
            }
            label={<T>label.pressure</T>}
            placeholder="0 Bar"
            errorMessage={
              <ErrorMessage
                fieldName="srvPressure"
                errorList={errors["srvPressure"]}
                touched={touched["srvPressure"]}
              />
            }
            width={100}
            data-hj-whitelist
          />

          <TextField
            name="srvBoltTemp"
            id="srvBoltTemp"
            data-testid="srvBoltTemp"
            onBlur={updateField}
            info={formatMessage(strings, "bolt.temperature-info")}
            error={
              touched["srvBoltTemp"] &&
              errors["srvBoltTemp"] &&
              errors["srvBoltTemp"].length > 0
            }
            width={160}
            label={<T>bolt.temperature</T>}
            placeholder={formatMessage(strings, "bolt.temperature")}
            errorMessage={
              <ErrorMessage
                fieldName="srvBoltTemp"
                errorList={errors["srvBoltTemp"]}
                touched={touched["srvBoltTemp"]}
              />
            }
            data-hj-whitelist
            data-dependentfields=""
          />
        </InputFormSection>
        <InputFormSectionHeader>
          <H4>
            <T>vdiCalculation.title</T>
          </H4>{" "}
        </InputFormSectionHeader>
        <InputFormSection>
          <RadioButtonGroup
            name="vdiCalculation"
            onChange={handleVdiCalculationToggle}
            data-testid="buttonGroup-vdiCalculation"
            data-hj-whitelist
            inline
          >
            <RadioButton
              type="radio"
              id="vdiCalculation1"
              data-testid="vdiCalculation1"
              value={"true"}
              checked={conditionValues["vdiCalculation"] === "true"}
            >
              <T>input.yes</T>
            </RadioButton>
            <RadioButton
              type="radio"
              id="vdiCalculation2"
              data-testid="vdiCalculation2"
              value={"false"}
              checked={conditionValues["vdiCalculation"] === "false"}
            >
              <T>input.no</T>
            </RadioButton>
          </RadioButtonGroup>
        </InputFormSection>
      </InputForm>
    );
  }
);

export default ConditionsDetails;
