import gql from "graphql-tag";

export const GET_ATTRIBUTES = gql`
  query getGasketAttributes($locale: Int!) {
    getGasketAttributes(locale: $locale) {
      attributes {
        name
        values {
          materialGroups {
            id
            material {
              materialName
              maxTemperature
              maxDiameter
              dbo
              hole
            }
          }
          boltAttributes
        }
      }
    }
  }
`;

export const GET_CALCULATION = gql`
  query getGasketCalculation(
    $tstPressure: String = "0"
    $srvTemperature: String = "20"
    $srvPressure: String = "0"
    $srvBoltTemp: Float!
    $gasketMaterial: String!
    $innerDiameter: String!
    $boltNumber: String!
    $boltMaterial: String!
    $boltLubrication: String!
    $calculationChoice: String!
    $boltTorqueAmount: String!
    $holeDiameter: String = "0"
    $boltDiameter: String!
    $outerDiameter: String!
    $locale: Int!
    $vdiCalculation: Boolean!
  ) {
    getGasketCalculation(
      tstPressure: $tstPressure
      srvTemperature: $srvTemperature
      srvPressure: $srvPressure
      srvBoltTemp: $srvBoltTemp
      gasketMaterial: $gasketMaterial
      innerDiameter: $innerDiameter
      boltNumber: $boltNumber
      boltMaterial: $boltMaterial
      boltLubrication: $boltLubrication
      calculationChoice: $calculationChoice
      boltTorqueAmount: $boltTorqueAmount
      holeDiameter: $holeDiameter
      boltDiameter: $boltDiameter
      outerDiameter: $outerDiameter
      locale: $locale
      vdiCalculation: $vdiCalculation
    ) {
      asmResultBar {
        initialRange
        finalRange
        result
      }
      tstResultBar {
        initialRange
        finalRange
        result
      }
      srvResultBar {
        initialRange
        finalRange
        result
      }
      torqueLoad {
        torque
        load
      }
      stresses {
        yieldService
        traction
        tractionLoadRatio
        tractionTorsion
        finalLoadRatio
      }
      actualArea
    }
  }
`;
